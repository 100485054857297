<template>
  <div class="news-page">
    <van-nav-bar title="资讯中心" left-arrow  @click-left="$router.go(-1)"/>
    <div class="content-padding">
      <div class="banner">
        <img  src="https://site-cdn.huami.com/files/amazfit/mobile/assets/news.jpg" alt="">
      </div>
      <div class="title">资讯中心</div>
      <div class="news-list">
        <div v-for="(item, index) in dataList" :key="index" class="item" @click="godis(item)">
          <img :src=item.image alt="">
          <div class="intro">
            <h2>{{item.title}}</h2>
            <p class="time">{{item.published_at|timeHandle}}</p>
            <p>{{item.description}}</p>
          </div>
        </div>
      </div>
      <div v-if="isMore" class="more">
        <span>滑动加载更多</span>
        <img src="https://site-cdn.huami.com/files/amazfit/mobile/assets/arrow2.png" alt="">
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from '../components/Footer'
export default {
  name: 'news',
  components: {
    Footer
  },
  data () {
    return {
      flag: true,
      dataList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      isMore: true
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollFun)
    this.getData()
  },
  methods: {
    scrollFun () {
      let scrollTop = this.getScrollTop()
      let height = this.getWindowHeight()
      let scrollHeight = this.getScrollHeight()
      let startFlag = scrollTop + height >= scrollHeight - 811
      if (this.flag && startFlag) {
        this.flag = false
        this.page = this.page + 1
        this.getData()
      }
    },
    getScrollTop () {
      let scrollTop = 0
      let bodyScrollTop = 0
      let documentScrollTop = 0
      if (document.body) {
        bodyScrollTop = document.body.scrollTop
      }
      if (document.documentElement) {
        documentScrollTop = document.documentElement.scrollTop
      }
      scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop
      return scrollTop
    },
    getWindowHeight () {
      let windowHeight = 0
      windowHeight = document.compatMode === 'CSS1Compat' ? document.documentElement.clientHeight : document.body.clientHeight
      return windowHeight
    },
    getScrollHeight () {
      let scrollHeight = 0
      let bodyScrollHeight = 0
      let documentScrollHeight = 0
      if (document.body) {
        bodyScrollHeight = document.body.scrollHeight
      }
      if (document.documentElement) {
        documentScrollHeight = document.documentElement.scrollHeight
      }
      scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight
      return scrollHeight
    },
    getData () {
      let that = this
      let dataList = that.dataList
      let page = that.page
      let pageSize = that.pageSize
      let config = { headers: { 'X-SITE': 'amazfit', 'X-LOCALE': 'cn' } }
      that.$http.get('https://api-site.huami.com/v1/news?news_type=news&page=' + page + '&per-page=' + pageSize, config).then((res) => {
        that.total = res.data.length
        that.dataList = dataList.concat(res.data)
        if (page * pageSize >= res.data.length) {
          that.isMore = false
        } else {
          that.flag = true
        }
      }).catch((error) => {
        that.$toast.fail(error.response.data.message)
      })
    },
    godis (e) {
      this.$router.push({ path: '/news-detail', query: { key: e.key } })
    }
  },
  filters: {
    timeHandle (e) {
      console.log(e)
      return e.substring(0, 16)
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollFun)
  }
}
</script>
<style lang="scss">
.news-page {
  background: #F5F2F5;
  .banner {
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius:5px;
    img {
      display: block;
      width: 100%;
    }
  }
  .title{
    border-left: 3px solid #333;
    padding-left: 3vw;
    color: #333;
    font-size: 4vw;
    font-weight: bold;
    margin-top: 5vw;
    margin-bottom: 3vw
  }
  .news-list{
    .item{
      background: #fff;
      border-radius: 1vw;
      overflow: hidden;
      margin-bottom: 3vw;
      .intro{
        padding: 2vw 3vw;
      }
      img{
        width: 100%;
        height: 60vw;
        object-fit: cover
      }
      h2{
        font-size: 4vw;
        font-weight: normal;
        color: #333;
        margin: 0;
        margin-bottom: 2vw
      }
      p{
        font-size: 3.5vw;
        color: #666;
        margin: 0;
        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 2; /** 显示的行数 **/
        overflow: hidden;  /** 隐藏超出的内容 **/
        &.time{
          margin-bottom: 2vw
        }
      }
    }

  }
  .more{
    display: flex;
    padding: 4vw 0;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    img{
      width: 5vw;
    }
  }
  a {
    color: #333;
  }
}
</style>
