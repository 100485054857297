<template>
  <product title="Amazfit GTS 2"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://equity.tmall.com/tm?agentId=731032&_bind=true&bc_fl_src=tmall_market_llb_1_895656&llbPlatform=_pube&llbOsd=1"
  ></product>
</template>

<script>
import { RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'neo',
  components: {
    Product
  },
  data () {
    return {
      slogan: '无边界轻薄设计 ｜ 血氧饱和度检测 ｜ 独立音乐播放 ｜ 智能语音助手',
      colors: [
        '流光金',
        '海豚灰',
        '曜石黑'
      ],
      price: 999,
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/1_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/2_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/2_pro_04.png'
        ], [
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/3_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gts2/3_pro_04.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/m/GTS2-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/m/GTS2-mobile.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/3.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/4.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/5.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/6.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/7.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/8.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/9.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/10.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/11.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/12.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/13.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/14.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/15.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gts2/16.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gts2/15-1.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gts2/15-2.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gts2/15-3.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gts2/17.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/17-3.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/17-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/17-1.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/18-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/19.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/20.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/21.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/22.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/23-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2/24-1.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
