<template>
  <div class="news-detail">
    <van-nav-bar title="资讯中心" left-arrow  @click-left="$router.go(-1)"/>
    <div class="content-padding">
      <div class="title">{{title}}</div>
      <div class="time">{{time}}</div>
      <div class="content" v-html="content"></div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from '../components/Footer'
export default {
  name: 'news-detail',
  components: {
    Footer
  },
  data () {
    return {
      title: '',
      content: '',
      time: '',
      img: ''
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      let that = this
      let key = this.$route.query.key
      let config = { headers: { 'X-SITE': 'amazfit', 'X-LOCALE': 'cn' } }
      that.$http.get('https://api-site.huami.com/v1/news/key-view?key=' + key, config).then((res) => {
        that.title = res.data.title
        that.content = res.data.content
        that.time = res.data.published_at.substring(0, 16)
      }).catch((error) => {
        that.$toast.fail(error.response.data.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.news-detail {
  .title{
    color: #333;
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
    margin-top: 5vw;
    margin-bottom: 3vw
  }
  img{
    width: 100%
  }
  .time{
    font-size: 3.5vw;
    color: #666;
    text-align: center;
    margin-bottom: 3vw
  }
}
</style>
