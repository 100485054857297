<template>
  <product title="Amazfit 表带 氟橡胶系列 轻盈透气款"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="139"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    isSwitchSlogan
    buy-link=""
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'slim-breath',
  components: {
    Product
  },
  data () {
    return {
      slogan: [
        '20mm',
        '22mm',
        '22mm',
        '22mm'
      ],
      colors: [
        '橙+灰',
        '深蓝+浅蓝',
        '深灰+浅灰',
        '黄绿+深蓝'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/1_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/2_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/3_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/4_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/4_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part1/4_pro_03.png'
        ]
      ],
      relativedProducts: [
        {
          name: 'Amazfit<br/>炫彩硅胶表带',
          image: CDN_URL + '/images/product/accessory/01.png',
          price: 59
        },
        {
          name: '米动手表青春版<br/>彩色腕带',
          image: CDN_URL + '/images/product/accessory/02.png',
          price: 49
        },
        {
          name: 'Amazfit<br/>氟橡胶表带',
          image: CDN_URL + '/images/product/accessory/03.png',
          price: 129
        },
        {
          name: 'Amazfit 表带<br/>真皮系列',
          image: CDN_URL + '/images/product/accessory/04.png',
          price: 129
        }
      ],
      detailImages: [
        'https://huami-site.cdn.bcebos.com/files/amazfit/mobile/parts/parts1/mobile.jpg'
      ],
      paramImages: [
        'https://huami-site.cdn.bcebos.com/files/amazfit/mobile/parts/parts1/parameter.gif'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
