<template>
  <product title="Amazfit GTR 2"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    is-switch-price
    buy-link="https://item.jd.com/100015491216.html"
  ></product>
</template>

<script>
import { RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'neo',
  components: {
    Product
  },
  data () {
    return {
      slogan: '无边界轻薄设计｜血氧饱和度检测｜独立音乐播放｜强劲续航',
      colors: [
        '运动款',
        '经典款'
      ],
      price: [
        999,
        1099
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gtr2/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gtr2/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gtr2/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gtr2/1_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gtr2/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gtr2/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gtr2/2_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/amazfit-cn/sku/gtr2/2_pro_04.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/gtr2/GTR2-mobile-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr2/GTR2-mobile.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/2-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/3-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/4-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/5-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/6-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/7-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/8-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/9-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/10-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/11-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/12-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/13-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/14-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/15-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/16-2.jpg'
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/14-3.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/15-4.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/16-3.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/17-3.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/17_1.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/18-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/19-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/20-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/21-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2/22-1.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
