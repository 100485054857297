<template>
  <product title="Amazfit 表带 氟橡胶系列 轻薄款"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="139"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    isSwitchSlogan
    buy-link="https://detail.tmall.com/item.htm?id=619408138586&skuId=4371247380907"
  ></product>
</template>

<script>
import Product from '../Product'

export default {
  name: 'rubber-slim-wristbands',
  components: {
    Product
  },
  data () {
    return {
      slogan: [
        '20mm',
        '20mm',
        '20mm',
        '22mm',
        '22mm',
        '22mm'
      ],
      colors: [
        '樱语粉',
        '湖水绿',
        '丁香紫',
        '热情红',
        '沉静蓝',
        '湖水绿'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/2_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/3_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/4_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/4_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/4_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/5_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/5_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/5_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/6_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/6_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/6_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/7_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/7_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/parts/part09/7_pro_03.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        'https://huami-site.cdn.bcebos.com/files/amazfit/cn/sku/parts/part09/detail/mobile.jpg'
      ],
      paramImages: [
        'https://huami-site.cdn.bcebos.com/files/amazfit/mobile/parts/parts1/parameter.gif'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
