<template>
  <product title="Amazfit 米动手表青春版1S"
    :swiper-data="swiperData"
    :slogan="slogan"
    price='399'
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://equity.tmall.com/tm?agentId=593363&_bind=true&bc_fl_src=tmall_market_llb_1_757987&llbPlatform=_pube&llbOsd=1"
    is-bus-city2
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'bip-s',
  components: {
    Product
  },
  data () {
    return {
      slogan: '轻盈多彩表身 | 30 天超长续航 <br/> huami- PAI<sup style="font-size:12px">TM</sup>健康评估系统 | GPS 双星定位',
      colors: [
        '赤橙',
        '碳黑',
        '岩白',
        '暖粉'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/bip-s/swiper/1.png',
          CDN_URL + '/images/product/bip-s/swiper/2.png',
          CDN_URL + '/images/product/bip-s/swiper/3.png'
        ],
        [
          CDN_URL + '/images/product/bip-s/swiper/4.png',
          CDN_URL + '/images/product/bip-s/swiper/5.png',
          CDN_URL + '/images/product/bip-s/swiper/6.png'
        ],
        [
          CDN_URL + '/images/product/bip-s/swiper/7.png',
          CDN_URL + '/images/product/bip-s/swiper/8.png',
          CDN_URL + '/images/product/bip-s/swiper/9.png'
        ],
        [
          CDN_URL + '/images/product/bip-s/swiper/10.png',
          CDN_URL + '/images/product/bip-s/swiper/11.png',
          CDN_URL + '/images/product/bip-s/swiper/12.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-01.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-02.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-03.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-04.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-05-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-06.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-07.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-08.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-09.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-10.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-11.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-12.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/bip-s/d-13.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
