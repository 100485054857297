<template>
  <product title="Amazfit GTR 2 eSIM"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="1999"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100019715500.html"
  ></product>
</template>

<script>
import { RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'gtr2e-sim',
  components: {
    Product
  },
  data () {
    return {
      slogan: '无边界轻薄设计｜血氧饱和度检测｜智能语音助手｜eSIM 独立通信',
      colors: [
        '曜石黑'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e-sim/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e-sim/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e-sim/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e-sim/1_pro_04.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/1.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr2e/GTR2esim-mobile.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/3.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/4.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/5.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/6.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/7.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/8.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/9.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/10.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/11.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/12.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/13.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/14.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/15.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/16.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/17.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/18.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/19.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/20.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/21.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/22.jpeg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e-sim/23.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
