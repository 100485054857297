<template>
  <product title="Amazfit Pop"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100016171660.html"
    is-switch-price
    is-bus-city3
  ></product>
</template>

<script>
import { RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'pop',
  components: {
    Product
  },
  data () {
    return {
      slogan: ' 高清 1.43 英寸彩屏 | 血氧检测<sup>1</sup> | 60+ 运动模式 | GPS 定位* | 5ATM 防水<sup>2</sup>',
      colors: [
        '普通版 樱粉',
        '普通版 炭黑',
        '普通版 松绿',
        'Pro 樱粉',
        'Pro 炭黑',
        'Pro 松绿'
      ],
      price: [
        349,
        349,
        349,
        399,
        399,
        399
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/1_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/2_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/3_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/1_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/2_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/pop/3_pro_03.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/01-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/02-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/03-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/04-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/05-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/06-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/07-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/08-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/09-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/10-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/11-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/12-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/13-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/14-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/15-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/16-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/17-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/18-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/19-2.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/20-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/pop/21-2.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
