<template>
  <product title="Amazfit Ares"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="499"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://equity.tmall.com/tm?agentId=610571&_bind=true&bc_fl_src=tmall_market_llb_1_775195&llbPlatform=_pube&llbOsd=1"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'ares',
  components: {
    Product
  },
  data () {
    return {
      slogan: '70 项运动模式 | FIRSTBEAT 运动分析 | huami-PAI<sup>TM</sup> 健康评估系统 <br />  14 天长续航*',
      colors: [
        '岩黑',
        '军绿'
      ],
      price: [
        499,
        499
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/ares/swiper/1.png',
          CDN_URL + '/images/product/ares/swiper/2.png',
          CDN_URL + '/images/product/ares/swiper/3.png'
        ],
        [
          CDN_URL + '/images/product/ares/swiper/4.png',
          CDN_URL + '/images/product/ares/swiper/5.png',
          CDN_URL + '/images/product/ares/swiper/6.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/ares/detail/01.jpg',
        CDN_URL + '/images/product/ares/detail/02.jpg',
        CDN_URL + '/images/product/ares/detail/03.jpg',
        CDN_URL + '/images/product/ares/detail/04.jpg',
        CDN_URL + '/images/product/ares/detail/05.jpg',
        CDN_URL + '/images/product/ares/detail/06.jpg',
        CDN_URL + '/images/product/ares/detail/07.jpg',
        CDN_URL + '/images/product/ares/detail/08.jpg',
        CDN_URL + '/images/product/ares/detail/09.jpg',
        CDN_URL + '/images/product/ares/detail/10.jpg',
        CDN_URL + '/images/product/ares/detail/11.jpg',
        CDN_URL + '/images/product/ares/detail/12.jpg',
        CDN_URL + '/images/product/ares/detail/13.jpg',
        CDN_URL + '/images/product/ares/detail/14.jpg',
        CDN_URL + '/images/common/zepp-black.jpg',
        CDN_URL + '/images/product/ares/detail/15.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/ares/detail/16.jpg',
        CDN_URL + '/images/product/ares/detail/17.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
