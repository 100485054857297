<template>
  <product title="Amazfit GTS 2e"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="799"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100017160532.html"
  ></product>
</template>

<script>
import { RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'gts2e',
  components: {
    Product
  },
  data () {
    return {
      slogan: '无边界轻薄设计 ｜ 超视网膜屏幕全天候显示 <br /> 血氧饱和度检测 ｜ 智能语音助手',
      colors: [
        '曜石黑',
        '暗夜绿',
        '罗兰紫'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/1_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/2_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/2_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/3_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2e/3_pro_04.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/3.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/4-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/5.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/6.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/7.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/8.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/9.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/10.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/11.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/12-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/13.jpeg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/14.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/15.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/16.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/17.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/18-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/19.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/20.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/21.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/22.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/23.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2e/24.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
