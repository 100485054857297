<template>
  <product title="Amazfit GTR 2e"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="799"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100017160528.html"
  ></product>
</template>

<script>
import { RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'gtr2e',
  components: {
    Product
  },
  data () {
    return {
      slogan: '无边界轻薄设计｜血氧饱和度检测｜智能语音助手｜强劲续航',
      colors: [
        '曜石黑',
        '海豚灰',
        '冰湖绿'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/1_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/1_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/2_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/2_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/2_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/2_pro_04.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/3_pro_03.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gtr2e/3_pro_04.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/gtr2e/GTR2e-mobile-kv.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/gtr2e/GTR2e-mobile.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/2-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/3-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/4-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/5-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/6-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/7-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/8-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/9-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/10-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/11.jpeg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/12-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/13-2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/14-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/15-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/16-1.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/17_1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/18_1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/19-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/20-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gtr2e/21-1.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
