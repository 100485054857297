<template>
  <product title="Amazift 商务运动表带"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="敬请期待"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://shop18009901.m.youzan.com/wscgoods/detail/35wp47udavb9h"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'sport-firm',
  components: {
    Product
  },
  data () {
    return {
      slogan: '20mm | 22mm',
      colors: [
        '20mm',
        '22mm'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/business-sport/swiper/1_pro_01.png',
          CDN_URL + '/images/product/business-sport/swiper/1_pro_02.png',
          CDN_URL + '/images/product/business-sport/swiper/1_pro_03.png'
        ],
        [
          CDN_URL + '/images/product/business-sport/swiper/2_pro_01.png',
          CDN_URL + '/images/product/business-sport/swiper/2_pro_02.png',
          CDN_URL + '/images/product/business-sport/swiper/2_pro_03.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/business-sport/detail/01.jpg',
        CDN_URL + '/images/product/business-sport/detail/02.jpg',
        CDN_URL + '/images/product/business-sport/detail/03.jpg',
        CDN_URL + '/images/product/business-sport/detail/04.jpg',
        CDN_URL + '/images/product/business-sport/detail/05.jpg',
        CDN_URL + '/images/product/business-sport/detail/06.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/business-sport/detail/07.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
