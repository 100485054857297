<template>
  <product title="Amazfit GTS 2 mini"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="699"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100016868402.html?cu=true&utm_source=kong&utm_medium=tuiguang&utm_campaign=t_1001006693_2871_3816_null"
  ></product>
</template>

<script>
import { RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'gts2-mini',
  components: {
    Product
  },
  data () {
    return {
      slogan: '时尚轻薄设计｜血氧饱和度检测｜70+ 运动模式｜14 天长续航',
      colors: [
        '深松绿',
        '蔷薇粉',
        '曜石黑'
      ],
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/1_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/1_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/1_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/4_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/4_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/4_pro_03.png'
        ],
        [
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/3_pro_01.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/3_pro_02.png',
          'https://site-cdn.huami.com/files/amazfit/cn/sku/gts2-mini/3_pro_03.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/2.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/3.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/4.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/5.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/6.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/7.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/8.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/9.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/10.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/11.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/12-1.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/13.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/14.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/15-1.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/gts2-mini/16-1.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
