<template>
  <div class="huangshan2">
    <van-nav-bar title="黄山 2 号" left-arrow  @click-left="$router.go(-1)"/>
    <div class="images">
      <div class="img" v-for="(item, key) in detailImages" :key="key" >
        <img :src="item" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { CDN_URL } from '@/config'
export default {
  name: 'huangshan2',
  data () {
    return {
      detailImages: [
        CDN_URL + '/images/product/huangshan2/detail/01.jpg',
        CDN_URL + '/images/product/huangshan2/detail/02.jpg',
        CDN_URL + '/images/product/huangshan2/detail/03.jpg',
        CDN_URL + '/images/product/huangshan2/detail/04.jpg',
        CDN_URL + '/images/product/huangshan2/detail/05.jpg'
      ]
    }
  }
}
</script>
<style lang="scss">
.huangshan2 {
  img {
    display: block;
    vertical-align: middle;
    border: none;
  }
}
</style>
