<template>
  <product title="Amazfit Neo"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="299"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://detail.tmall.com/item.htm?id=626431894995"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'neo',
  components: {
    Product
  },
  data () {
    return {
      slogan: '复古设计 | 24小时心率监测 | 50 米深度防水 | 4 周超长续航',
      colors: [
        '经典黑',
        '草灰绿',
        '珊瑚橙'
      ],
      price: [
        299,
        299,
        299
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/neo/swiper/1.png',
          CDN_URL + '/images/product/neo/swiper/2.png',
          CDN_URL + '/images/product/neo/swiper/3.png'
        ],
        [
          CDN_URL + '/images/product/neo/swiper/4.png',
          CDN_URL + '/images/product/neo/swiper/5.png',
          CDN_URL + '/images/product/neo/swiper/6.png'
        ],
        [
          CDN_URL + '/images/product/neo/swiper/7.png',
          CDN_URL + '/images/product/neo/swiper/8.png',
          CDN_URL + '/images/product/neo/swiper/9.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/neo/detail/1.jpg',
        CDN_URL + '/images/product/neo/detail/2.jpg',
        CDN_URL + '/images/product/neo/detail/3.jpg',
        CDN_URL + '/images/product/neo/detail/4.jpg',
        CDN_URL + '/images/product/neo/detail/5.jpg',
        CDN_URL + '/images/product/neo/detail/6.jpg',
        CDN_URL + '/images/product/neo/detail/7.jpg',
        CDN_URL + '/images/product/neo/detail/8.jpg',
        CDN_URL + '/images/product/neo/detail/9.jpg',
        CDN_URL + '/images/product/neo/detail/10.jpg',
        CDN_URL + '/images/product/neo/detail/11.jpg',
        CDN_URL + '/images/product/neo/detail/12.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/neo/detail/13.jpg',
        CDN_URL + '/images/product/neo/detail/14.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
