<template>
  <product title="Amazfit 羚羊轻户外跑鞋 2"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="249"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.646b687dYwbXbZ&id=620792651085&skuId=4381830732677"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'antelope2',
  components: {
    Product
  },
  data () {
    return {
      slogan: '便捷系带旋钮 | 非牛顿流体材料中底 | GOODYEAR 橡胶大底 <br/> FOOTBIRD 防滑缓震鞋垫',
      colors: [
        '极速灰',
        '动感黑'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/antelope2/swiper/1_pro_01.png',
          CDN_URL + '/images/product/antelope2/swiper/1_pro_02.png',
          CDN_URL + '/images/product/antelope2/swiper/1_pro_03.png'
        ],
        [
          CDN_URL + '/images/product/antelope2/swiper/2_pro_01.png',
          CDN_URL + '/images/product/antelope2/swiper/2_pro_02.png',
          CDN_URL + '/images/product/antelope2/swiper/2_pro_03.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/antelope2/detail/01.jpg',
        CDN_URL + '/images/product/antelope2/detail/02.jpg',
        CDN_URL + '/images/product/antelope2/detail/03.jpg',
        CDN_URL + '/images/product/antelope2/detail/04.jpg',
        CDN_URL + '/images/product/antelope2/detail/05.jpg',
        CDN_URL + '/images/product/antelope2/detail/06.jpg',
        CDN_URL + '/images/product/antelope2/detail/07.jpg',
        CDN_URL + '/images/product/antelope2/detail/08.jpg',
        CDN_URL + '/images/product/antelope2/detail/09.jpg',
        CDN_URL + '/images/product/antelope2/detail/10.jpg',
        CDN_URL + '/images/product/antelope2/detail/11.jpg',
        CDN_URL + '/images/product/antelope2/detail/12.jpg',
        CDN_URL + '/images/product/antelope2/detail/13.jpg',
        CDN_URL + '/images/product/antelope2/detail/14.jpg',
        CDN_URL + '/images/product/antelope2/detail/15.jpg',
        CDN_URL + '/images/product/antelope2/detail/16.jpg',
        CDN_URL + '/images/product/antelope2/detail/17.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/antelope2/detail/18.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
